export const CONFIGURACION = "/configuracion";

export const LOGOUT = "/logout";

export const LOGIN = "/login";

export const LANDING_PAGE = "/home";

export function HOME(companyCode) {
  return `/${companyCode}`;
}

export function POST(id) {
  return `/${id}/post`;
}

export function USER(id) {
  return `./${id}/client`;
}

export const NEW_CLIENT = `./new-client`;

export function BOOK(companyCode) {
  return `/${companyCode}/book`;
}
// dashboard

export function STAFF(companyCode) {
  return `/${companyCode}/dashboard/staff`;
}

export function SCHEDULE(companyCode) {
  return `/${companyCode}/dashboard/schedule`;
}

export function SERVICES(companyCode) {
  return `/${companyCode}/dashboard/services`;
}

export function SUPPORT(companyCode, issue) {
  if (issue) {
    return `/${companyCode}/dashboard/support/${issue}`;
  }
  return `/${companyCode}/dashboard/support`;
}

export function SCHEDULED(companyCode) {
  return `/${companyCode}/dashboard/scheduled`;
}

export function FINANCE(companyCode) {
  return `/${companyCode}/dashboard/finance`;
}

//Error
export function ERROR_500(companyCode) {
  return `/${companyCode}/error/500`;
}

export function ERROR_400(companyCode) {
  return `/${companyCode}/error/400`;
}

export function ERROR_404(companyCode) {
  return `/${companyCode}/error/404`;
}

export default {
  CONFIGURACION,
  HOME,
  POST,
  USER,
  BOOK,
  STAFF,
  SCHEDULE,
  SCHEDULED,
  FINANCE,
  ERROR_500,
  ERROR_400,
  ERROR_404,
  SUPPORT,
  SERVICES,
  LOGOUT,
};
