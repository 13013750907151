// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};
console.log("redux", config.storageTokenKeyName);
export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    logged: false,
  },
  reducers: {
    handleLogin: (state, action) => {
      state.logged = true;
      state.userData = action.payload;
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName];
      // localStorage.setItem("userData", JSON.stringify(action.payload));
      // localStorage.setItem("company", JSON.stringify(action.payload.user.company));
      // console.log("00000handleLogin1User", JSON.stringify(action.payload.user));
      localStorage.setItem(config.storageTokenKeyName, action.payload[config.storageTokenKeyName]);
    },
    handleLogout: (state) => {
      state.logged = false;
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      // localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
    },
    setIdLogged: (state) => {
      state.logged = true;
    },
  },
});

export const { handleLogin, handleLogout, setIdLogged } = authSlice.actions;

export const isUserLogged = (state) => state.auth.logged;

export default authSlice.reducer;
